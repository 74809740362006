<template>
  <div style="height: 100%">
    <!--【版头】标题 -->
    <nesi-user-common :title="`用户信息`">
      <!--【版头】查询条件 -->
      <template #query>
        <el-row>
          <el-col :span="20" style="border: 0px solid red">
            <!-- 上传头像 -->
            <div style="padding: 20px 0px 20px 0px; text-align: center">
              <el-upload
                ref="upload"
                action="https://jsonplaceholder.typicode.com/posts/"
                accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG"
                :show-file-list="false"
                :auto-upload="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :on-change="onchange"
              >
                <div v-if="userAvatarSrc">
                  <el-image
                    id="inlogeewm"
                    :src="userAvatarSrc"
                    class="user-avatar-img"
                  ></el-image>
                </div>
                <div v-else class="user-avatar-non">
                  <span>未上传</span>
                </div>
                <el-button type="primary" style="margin: 20px 0px 10px 0px"
                  >上传头像</el-button
                >
                <div style="width: 180px; font-size: 13px; color: #a0a0a0">
                  <span>注：建议上传长宽1:1的头像，大小请控制在500k以内。</span>
                </div>
              </el-upload>
            </div>
            <el-form
              :inline="true"
              :model="userInfoForm"
              class="demo-form-inline"
            >
              <el-form-item label="真实姓名" label-width="200px">
                <el-input
                  v-model="userInfoForm.realname"
                  readonly
                  placeholder="请输入真实姓名"
                  type="text"
                  style="width: 400px"
                />
              </el-form-item>
              <el-form-item label="证件类型" label-width="200px">
                <el-input
                  v-model="userInfoForm.typeNo"
                  readonly
                  placeholder="请选择证件类型"
                  type="text"
                  style="width: 400px"
                />
              </el-form-item>
              <el-form-item label="身份证号" label-width="200px">
                <el-input
                  v-model="userInfoForm.cardNo"
                  readonly
                  placeholder="请输入身份证号"
                  type="text"
                  style="width: 400px"
                />
              </el-form-item>
              <el-form-item label="手机号码" label-width="200px">
                <el-input
                  v-model="userInfoForm.phone"
                  readonly
                  placeholder="请输入手机号码"
                  type="text"
                  style="width: 400px"
                />
              </el-form-item>
              <el-form-item label="通讯地址" label-width="200px">
                <el-input
                  v-model="userInfoForm.address"
                  clearable
                  placeholder="请输入通讯地址"
                  type="textarea"
                  :rows="2"
                  maxlength="128"
                  style="width: 400px"
                />
              </el-form-item>
              <el-form-item style="text-align: center; width: 800px">
                <el-button
                  type="primary"
                  @click="editUserInfo"
                  :loading="loading"
                  >保存提交</el-button
                >
                <el-button
                  type="primary"
                  plain
                  @click="userPasswordDialogVisible = true"
                  >修改密码</el-button
                >
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </template>
    </nesi-user-common>

    <!--【弹窗】修改密码 -->
    <el-dialog
      title="修改密码"
      width="600px"
      v-model="userPasswordDialogVisible"
    >
      <el-form
        :inline="true"
        :model="userPasswordForm"
        class="demo-form-inline"
      >
        <el-form-item label="密码" label-width="150px">
          <el-input
            v-model="userPasswordForm.password"
            clearable
            placeholder="请输入密码"
            type="password"
            maxlength="128"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item label="新密码" label-width="150px">
          <el-input
            v-model="userPasswordForm.newPassword"
            clearable
            placeholder="请输入新密码"
            type="password"
            maxlength="255"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item label="确认密码" label-width="150px">
          <el-input
            v-model="userPasswordForm.confirmPassword"
            clearable
            placeholder="请输入确认密码"
            type="password"
            maxlength="64"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item label="短信验证" label-width="150px">
          <el-input
            v-model="userPasswordForm.code"
            clearable
            placeholder="请输入短信验证码"
            type="text"
            maxlength="64"
            style="width: 170px"
          />
          <el-button
            type="primary"
            @click="sendUserCode"
            style="margin-left: 20px"
            >手机验证码</el-button
          >
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetUserPasswordForm(0)">取消</el-button>
          <el-button type="primary" @click="editUserPassword">提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ElMessage } from 'element-plus'
import { defineComponent, reactive, ref, computed, onMounted } from 'vue'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import {
  updateUserInfo,
  updateUserPassword,
  sendVerificationCode
} from '@/api/user-manage'

import { useStore } from 'vuex'
import CryptoJS from 'crypto-js'

defineComponent({
  NesiUserCommon
})
// 获取登录用户信息
const store = useStore()
const user = computed(() => store.getters.userInfo).value
const loading = ref(false)

// 用户信息对象
const userInfoForm = reactive({
  realname: '',
  typeNo: '身份证',
  cardNo: '',
  phone: '',
  address: '',
  avatar: ''
})

// 用户头像路径（预览）
const userAvatarSrc = ref('')
// 用户头像获取
const onchange = (file, filelist) => {
  userAvatarSrc.value = URL.createObjectURL(file.raw)
  const reader = new FileReader()
  reader.readAsDataURL(file.raw)
  reader.onload = function (e) {
    userInfoForm.avatar = e.target.result
  }
}
// 修改信息
const editUserInfo = async () => {
  try {
    loading.value = true
    const resp = await updateUserInfo(userInfoForm)
    // 刷新一次西悉尼
    await store.dispatch('user/getUserInfo')
    ElMessage({ type: 'success', message: '修改信息成功' })
  } finally {
    loading.value = false
  }
}

// 修改密码
const userPasswordDialogVisible = ref(false)
const userPasswordForm = reactive({
  password: '',
  newPassword: '',
  confirmPassword: '',
  code: ''
})
const resetUserPasswordForm = (type) => {
  userPasswordForm.password = ''
  userPasswordForm.newPassword = ''
  userPasswordForm.confirmPassword = ''
  userPasswordForm.code = ''
  if (type === 0) {
    userPasswordDialogVisible.value = false
  }
}
const editUserPassword = async (row) => {
  if (userPasswordForm.password !== '') {
    if (userPasswordForm.newPassword === '') {
      ElMessage({ type: 'error', message: '新密码不能为空' })
    }
    if (userPasswordForm.confirmPassword === '') {
      ElMessage({ type: 'error', message: '新密码确认不能为空' })
    }
    if (userPasswordForm.newPassword !== userPasswordForm.confirmPassword) {
      ElMessage({ type: 'error', message: '新密码确认有误' })
    }
    const key = user.username.substring(0, 8) + 'Easyma3-'
    userPasswordForm.password = encryptByDES(
      userPasswordForm.password,
      key,
      key
    )
    userPasswordForm.newPassword = encryptByDES(
      userPasswordForm.newPassword,
      key,
      key
    )
    userPasswordForm.confirmPassword = encryptByDES(
      userPasswordForm.confirmPassword,
      key,
      key
    )
  }
  const editResp = await updateUserPassword(userPasswordForm)
  console.log(editResp)
  ElMessage({ type: 'success', message: '修改密码成功' })
  resetUserPasswordForm(0)
}

// 发送短信
const sendUserCode = async () => {
  const data = {
    type: '1'
  }
  const resp = await sendVerificationCode(data)
  console.log(resp)
  ElMessage({ type: 'success', message: '发送成功，请查看手机短信验证码' })
}

// des 加密
const encryptByDES = (message, key, iv) => {
  const keyHex = CryptoJS.enc.Utf8.parse(key)
  const keyIv = CryptoJS.enc.Utf8.parse(iv)
  const srcs = CryptoJS.enc.Utf8.parse(message)
  const encrypted = CryptoJS.AES.encrypt(srcs, keyHex, {
    iv: keyIv,
    mode: CryptoJS.mode.CBC
  })
  return encrypted.toString()
}
const minioUrl = ref('')

async function fetchUserInfo() {
  const res = await store.dispatch('user/getUserInfo')
  const { userInfo, minioUrl: hostUrl } = res

  userInfoForm.realname = userInfo.realname
  userInfoForm.typeNo = '身份证'
  userInfoForm.cardNo = userInfo.cardNo
  userInfoForm.phone = userInfo.phone
  userInfoForm.address = userInfo.address
  userInfoForm.avatar = ''
  minioUrl.value = hostUrl
  if (userInfo.avatar) {
    userAvatarSrc.value = userInfo.avatar.startsWith('http')
      ? userInfo.avatar
      : hostUrl + userInfo.avatar
  } else {
    userAvatarSrc.value = ''
  }
}

onMounted(() => {
  fetchUserInfo()
})
</script>
<style lang="scss">
.user-avatar-img {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  border: 1px solid #c0c0c0;
}

.user-avatar-non {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  border: 1px solid #c0c0c0;
  background-color: #e0e0e0;
  padding-top: 90px;
  font-size: 16px;
  color: #a0a0a0;
}

.user-qrcode-img {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  border: 1px solid #c0c0c0;
}

.user-qrcode-non {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  border: 1px solid #c0c0c0;
  background-color: #e0e0e0;
  padding-top: 90px;
  font-size: 16px;
  color: #a0a0a0;
}
</style>
